// DICOM utilities
// ---------------

// Dependencies
import Vue from "vue";
import { Observable } from "rxjs";
import { schemaToStackMap } from "@/js/preferences";
import { postStudy, sendDicomFiles } from "@/js/api.schema";

// Private methods

// const randomString = n => {
//   var p = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
//   return [...Array(n)].reduce(a => a + p[~~(Math.random() * p.length)], "");
// };

// Split PatientName metadata into first and last name, accordin to db schema
//  For human use, the five components in their order of occurrence are:
// family name complex, given name complex, middle name, name prefix, name suffix.
// Any of the five components may be an empty string.
// The component delimiter shall be the caret "^" character (5EH).
const splitPatientName = patient => {
  const name = patient.first_name || "";
  let [a, b] = name.split("^");
  if (!b) {
    // try splitting spaces
    [a, ...b] = name.split(" ");
  } else {
    b = [b];
  }
  patient.first_name = a;
  patient.last_name = b.join(" ");
  return patient;
};

// Extract schema data (grouped by studies) from stacks and convert to upload object
const stacksToUploadFormat = stacks => {
  let result = stacksToSchemaFormat(stacks);

  // Remove metadata ids (will be assigned by the server)
  // Convert metadata pixel spacing from array to single items
  result = result.map(study => {
    delete study.id;
    delete study.patient.id;
    study.series.forEach(s => {
      s.pixel_spacing_x = s.pixel_spacing ? s.pixel_spacing[0] : null;
      s.pixel_spacing_y = s.pixel_spacing ? s.pixel_spacing[1] : null;
      delete s.pixel_spacing;
      delete s.id;
    });
    return study;
  });

  // convert iso date time strings to date strings
  result.forEach(d => {
    d.study_date = d.study_date ? d.study_date.substring(0, 10) : d.study_date;
    d.patient.birth_date = d.patient.birth_date
      ? d.patient.birth_date.substring(0, 10)
      : d.patient.birth_date;
  });

  return result;
};

// Upload all series files of a study, using queues
const uploadSeriesInStudy = study => {
  const uploadQueueIds = study.series.map(s => [
    s.id,
    s.series_id,
    s.number_of_slices
  ]);

  const nextSeries = async sub => {
    if (!uploadQueueIds.length) {
      sub.complete();
      return;
    }

    const [dbSeriesId, seriesId, numberOfInstances] = uploadQueueIds.shift();
    console.warn("START UPLOAD FOR SERIES", dbSeriesId);
    console.time(`Series ${dbSeriesId} upload`);
    sub.next({ start: true, seriesId, numberOfInstances });

    const stack = Vue.prototype.$ditto.dicom.getSeriesStack(seriesId);
    const sendSubscription = sendDicomFiles(dbSeriesId, stack)
      .subscribe({
        // emit data to study observer
        next: data => sub.next({ seriesId, ...data }),
        error: error => sub.error({ seriesId, error })
      })
      .add(() => {
        console.warn("END UPLOAD FOR SERIES", dbSeriesId);
        console.timeEnd(`Series ${dbSeriesId} upload`);
        sub.next({ seriesId, end: true });
        sendSubscription?.unsubscribe();
        nextSeries(sub);
      });
  };

  return new Observable(subscriber => nextSeries(subscriber));
};

// Public methods

export const getTypedArray = data => {
  let strRepr = data.repr == 0 ? "Uint" : "Int";
  let strBits = data.bits.toString();

  switch (strRepr + strBits) {
    case "Uint8":
      return new Uint8Array(data.rows * data.cols * data.number_of_slices);
    case "Int8":
      return new Int8Array(data.rows * data.cols * data.number_of_slices);
    case "Uint16":
      return new Uint16Array(data.rows * data.cols * data.number_of_slices);
    case "Int16":
      return new Int16Array(data.rows * data.cols * data.number_of_slices);
    default:
  }
};

// Extract schema data (grouped by studies) from stacks
export const stacksToSchemaFormat = stacks => {
  const convertGroup = (group, stack) => {
    return Object.keys(schemaToStackMap[group]).reduce(
      (result, schemaKey) => ({
        ...result,
        [schemaKey]: stack[schemaToStackMap[group][schemaKey]]
      }),
      {}
    );
  };

  return stacks.reduce((allStacks, stack) => {
    const series = convertGroup("series", stack);
    let rStudy = allStacks.find(
      ({ id }) => id == stack[schemaToStackMap.study.id]
    );
    if (rStudy) {
      rStudy.series.push(series);
    } else {
      let patient = convertGroup("patient", stack);
      patient = splitPatientName(patient);

      allStacks.push({
        ...convertGroup("study", stack),
        patient: patient,
        series: [series]
      });
    }
    return allStacks;
  }, []);
};

// Upload dicom data using queues
export const uploadStudies = data => {
  const uploadQueue = stacksToUploadFormat(data);

  const nextStudy = async sub => {
    if (!uploadQueue.length) {
      sub.complete();
      return;
    }

    // Await for post study, then send all series files
    const study = uploadQueue.shift();
    let savedStudy;
    console.warn("START UPLOAD FOR STUDY", study.study_id);

    try {
      savedStudy = await postStudy(study);
      sub.next({
        startUpload: true
      });
      savedStudy.series = savedStudy.series.map(s => ({
        ...s,
        number_of_slices: study.series.find(r => r.series_id == s.series_id)
          .seriesInstanceUIDs.length
      }));

      // some series in study can have errors (files will not be sent)
      if (savedStudy.errors) {
        savedStudy.errors.forEach(e => {
          // omit keys user does not need to see
          // eslint-disable-next-line no-unused-vars
          var { series_id, serie_index, ...e2 } = e;
          sub.next({
            error: e2,
            seriesId: study.series[e.serie_index]?.series_id
          });
        });
      }
    } catch (error) {
      // post fails, all series in study have an upload error
      const seriesIds = study.series.map(s => s.series_id);
      seriesIds.forEach(id =>
        sub.next({
          error: typeof error.body == "object" ? error.body : error,
          seriesId: id
        })
      );

      // try with next study
      nextStudy(sub);
      return;
    }

    // Send series files
    const uploadSeriesSubscription = uploadSeriesInStudy(savedStudy)
      .subscribe({
        // emit data to main observer
        next: data => sub.next(data),
        error: error => sub.error(error)
      })
      .add(() => {
        console.warn("END UPLOAD FOR STUDY", savedStudy.id);
        sub.next({
          uploadedStudy: data
        });
        uploadSeriesSubscription?.unsubscribe();
        nextStudy(sub);
      });
  };

  return new Observable(subscriber => nextStudy(subscriber));
};
