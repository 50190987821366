import { BehaviorSubject } from "rxjs";
let ws = null;
export let seriesComponentMapping = {};
let onCloseListener, onErrorListener;
import store from "@/store/index";
import Vue from "vue";

import { DownloadManager } from "dv-download-manager";
export let dm = new DownloadManager("alternate", false);

import { decode } from "@msgpack/msgpack/dist";
const URL =
  window.location.protocol == "https:"
    ? `wss:/${window.location.host}/ws/series/download/`
    : `ws:/${window.location.host}/ws/series/download/`;

const wsStatus = new BehaviorSubject();

export const statusSource = wsStatus;

export const downloadDicomFiles = async (
  seriesId,
  elementId,
  images,
  updateProgress
) => {
  seriesComponentMapping[seriesId] = updateProgress;

  // add series to the download manager
  dm.addSeries(seriesId, seriesId, elementId, images);

  // start downloading if the download is not already in progress
  processDownloadQueue();
};

export const stopDownloadingDicomFiles = id => {
  dm.removeSeries(id);
};

// interface with download manager
export function processDownloadQueue() {
  // get next slice to download
  let slot = dm
    .getNextSlot(1)
    .slice()
    .pop();

  if (!slot) {
    return;
  }

  // prepare the message
  const wsMessage = {
    data: {
      images: [slot.imageId], // ids of the series' images object from the database
      elementIds: [slot.studyId]
    }
  };
  // send the message
  send(JSON.stringify(wsMessage));
}

export function openSeriesWebsocket(
  userID,
  token,
  onWsClose,
  onWsError,
  router
) {
  if (ws) return;
  const onClose = event => {
    console.warn("WS", event.type, event);
    wsStatus.next(ws.readyState);
    onWsClose(event);
  };

  const onError = event => {
    console.warn("WS", event.type, event);
    if (ws.readyState == WebSocket.OPEN) {
      onWsError(event);
    }
  };

  ws = new WebSocket(URL + userID + `/`, ["bearer", token]);
  wsStatus.next(ws.readyState);

  ws.onopen = () => {
    console.warn("Connected to WebSocket");
    wsStatus.next(ws.readyState);
  };

  onCloseListener = event => onClose(event);
  onErrorListener = event => onError(event);

  ws.onclose = onCloseListener;
  ws.onerror = onErrorListener;

  ws.onmessage = event => {
    console.log(event.data);
  };
  const onReceiveData = async (message, error) => {
    // Even when moving away from viewer during the download, 'progress' is null
    // viewer/downloadEnded already called on Viewer.vue::beforeDestroy()
    if (
      router.history.current.name !== "viewer" &&
      router.history.current.name !== "shared"
    )
      return;

    let data;
    if (message.data instanceof Blob) {
      data = decode(await message.data.arrayBuffer()).data;
    } else {
      store.commit("raiseSnackbar", {
        text: error || "Failed to download series: no data received",
        color: "error",
        timeout: -1
      });
      return;
    }
    if (data[0].annotations.length > 0) {
      store.commit("viewer/setAnnotations", {
        imageId: data[0].id,
        annotations: data[0].annotations
      });
    }
    // render and/or update larvitarManager or seriesStack
    const file = new File([data[0].blob], data[0].image_id);
    Vue.prototype.$ditto.dicom
      .parseFile(data[0].series_id, file, data[0].element_id)
      .then(({ imageIds, imageId }) => {
        seriesComponentMapping[data[0].series_id](
          data[0].series_id,
          data[0].element_id,
          imageIds,
          imageId
        );
        const progress = dm.getStatus(data[0].series_id);

        if (progress === null) {
          // download completed for the current series,
          console.warn("Download ends for series", data[0].series_id);
          store.commit("viewer/downloadEnded", {
            seriesId: data[0].series_id
          });
          store.commit("raiseSnackbar", {
            text: `Download ends for series ${data[0].series_id}: advanced views are now available`,
            color: "primary",
            timeout: 2000
          });
          dm.removeSeries(data[0].series_id);
        } else {
          // continue downloading
          processDownloadQueue();
        }
      })
      .catch(error => {
        console.error(error);
        store.commit("raiseSnackbar", {
          text: error || "Failed to download series",
          color: "error",
          timeout: -1
        });
        return;
      });
  };

  setOnMessage(onReceiveData);
  setOnError(onReceiveData);
}

export function setOnMessage(onmessage) {
  ws.onmessage = onmessage;
}
export function setOnError(onerror) {
  ws.onerror = onerror;
}

export const canSendMessage = () => {
  return ws !== null && typeof ws.send === "function";
};

export const send = message => ws.send(message);

export const disconnect = () => {
  if (ws && typeof ws.close === "function") {
    ws.close();
    ws = null;
  }
};
