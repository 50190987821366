let wsDict = {};
// let connectionAttempts; TODO
// let onCloseListener, onErrorListener;

// NOTE: ws are stored by group (ie the function, eg masks, surfaces, etc)
// and by id (at the moment userID, but we should change it to something more useful
// when we will have solved the problem of recognize which user is connected to the ws
// from server side)

const URL =
  window.location.protocol == "https:"
    ? `wss:/${window.location.host}/ws`
    : `ws:/${window.location.host}/ws`;

export const connect = (group, id, onMessage, onClose, onError) => {
  // TODO only if ws2 not already running: can remove this when mask unload will be restored to editing view unmount
  // if (wsDict[id]) {
  //   return;
  // } // TODO ask Sara

  if (!wsDict[group]) {
    wsDict[group] = {};
  }

  return new Promise(resolve => {
    wsDict[group][id] = new WebSocket(`${URL}/${group}/${id}/`, [
      "bearer",
      localStorage.getItem("access-token")
    ]);

    wsDict[group][id].onclose = event => onClose(event);
    wsDict[group][id].onerror = event => onError(event);
    wsDict[group][id].onmessage = event => onMessage(event);

    wsDict[group][id].onopen = () => {
      console.warn("WS connected", group, id);
      resolve();
    };
  });
};

export const disconnect = (group, id) => {
  if (wsDict[group] && wsDict[group][id]) {
    wsDict[group][id].onclose = null;
    wsDict[group][id].onerror = null;
    wsDict[group][id].close();
    delete wsDict[group][id];

    console.warn("WS disconnected", group, id);
  }
};

export const send = (group, id, message) => wsDict[group][id].send(message);
