<template>
  <div class="h-100">
    <app-home />

    <v-dialog persistent max-width="350" v-model="dialog.show">
      <v-card>
        <v-card-title class="headline">{{ dialog.title }}</v-card-title>
        <v-card-text>{{ dialog.text }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="D1"
            v-if="dialog.action"
            color="primary"
            text
            @click="dialog.action.onClick"
          >
            {{ dialog.action.title }}
          </v-btn>
          <v-btn id="D2" text @click="dialog.show = false"> Dismiss </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { createRgbVarsForThemes } from "@/js/utils.vuetify.css";
import { getPredictors, getUserSettings } from "@/js/api.schema";
import * as wsSingle from "@/js/api.websocket.single";
import {
  disconnect as disconnectSriesDownload,
  openSeriesWebsocket as openDownloadWs
} from "@/js/api.series.download";
import {
  disconnect as disconnectSriesUpload,
  openSeriesWebsocket as openUploadWs
} from "@/js/api.series.upload";
import AppHome from "@/views/Home";
import { mapGetters } from "vuex";

export default {
  name: "App",
  components: { AppHome },
  data() {
    return {
      dialog: { show: false }
      // reconnectionCounter: 0
    };
  },
  created() {
    // Connect to websocket, disconnect on page unload

    try {
      wsSingle.disconnect();
      disconnectSriesDownload();
      disconnectSriesUpload();
    } catch (error) {
      // Handle the error here, you can log it or perform other actions.
      console.error("An error occurred:", error);
    }
  },
  mounted() {
    createRgbVarsForThemes(this.$vuetify.theme.themes.light);
  },
  computed: mapGetters("auth", ["isAuth"]),
  methods: {
    isDownloadEnabled() {
      return true; //return !this.isAuth;
    },
    isUploadEnabled() {
      return true; // return this.isAuth && !this.$isDemoUser;
    },
    connectWebsocket() {
      const onClose = event => {
        this.dialog = {
          action: {
            onClick: () => {
              this.dialog.show = false;
              this.connectWebsocket();
            },
            title: "try-again"
          },
          show: true,
          text: `URL:${event.target.url} EVENT:${event.type}`,
          title: "Websocket connection error"
        };
      };
      const onError = event =>
        (this.dialog = {
          show: true,
          text: `URL:${event.target.url} EVENT:${event.type}`,
          title: "Websocket connection error"
        });

      const userId =
        (this.$store.state.auth.user && this.$store.state.auth.user.uuid) ||
        this.user;
      if (this.isDownloadEnabled())
        openDownloadWs(
          userId,
          localStorage.getItem("access-token"),
          onClose,
          onError,
          this.$router
        );
      if (this.isUploadEnabled())
        openUploadWs(
          userId,
          localStorage.getItem("access-token"),
          onClose,
          onError
        );
    },
    async loadPredictors() {
      // get all predictors
      const predictors = await getPredictors();
      this.$store.commit("viewer/initPredictors", predictors);
    },
    loadUserSettings() {
      getUserSettings()
        .then(data => {
          // update store values
          this.$store.commit("viewer/initOptions", data);
        })
        .catch(error => console.error(error));
    }
  },
  watch: {
    isAuth(value) {
      this.$store.commit("viewer/initViewerSessionId");
      if (value) {
        this.loadPredictors();
        this.loadUserSettings();
        this.connectWebsocket();
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/utils.scss";
@import "@/styles/fonts.scss";
@import "@/styles/main.scss";
</style>
