import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import dashboardModule from "./modules/dashboard";
import viewerModule from "./modules/viewer";

export default new Vuex.Store({
  state: {
    overlay: { show: false, text: "" },
    snackbar: { show: false, text: "", color: "", timeout: -1 },
    darkMode: true
  },
  getters: {
    darkMode: state => state.darkMode
  },
  mutations: {
    closeSnackbar: state =>
      (state.snackbar = { show: false, text: "", color: "" }),
    raiseSnackbar: (state, { text, color, timeout }) =>
      (state.snackbar = { show: true, text, color, timeout: timeout || -1 }),
    toggleOverlay: (state, { show, text }) => {
      state.overlay.show = show;
      state.overlay.text = text ? text : "";
    },
    toggleDarkMode: state => {
      state.darkMode = !state.darkMode;
    }
  },
  modules: {
    dashboard: dashboardModule,
    viewer: viewerModule
  }
});
