<template>
  <v-card class="text-center" flat :style="{ top: `${top}px` }">
    <v-card-text class="pa-2">
      <slot>This section is subjected to subscription.</slot>
      <div>Contact us for more information and quotes.</div>
    </v-card-text>
    <v-card-actions>
      <div class="d-flex mx-auto">
        <v-btn text color="primary" @click="contactUs">
          Contact us
        </v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "SubscriptionAlert",
  props: {
    top: { default: 0, type: Number }
  },
  methods: {
    contactUs() {
      window.open(process.env.APP_CUSTOMER.contactUs, "_newtab");
    }
  }
};
</script>
